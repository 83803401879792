<template>
  <div class="invoice-wrapper">

    <div class="row">

      <div class="col-xl-1 col-md-2">
        <b-button class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
                  variant="white"
                  @click="$router.back()">
          <feather-icon class="txt-green" icon="ChevronLeftIcon" size="20"/>
        </b-button>
      </div>

      <div class="col-xl-9 col-md-7">
        <div class="card invoice-preview-card">
          <div class="col-md-1 logo-wrapper">
            <p class="in-ribbon">Unpaid</p>
          </div>
          <div class="card-body invoice-padding pb-0">
            <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 align-items-center"
            >
              <div class="text-center text-md-left">
                <h2 class="txt-black font-weight-bolder mb-2">Statement #2343567</h2>
                <p class="card-text mb-25">
                  Issue Date: <strong>25/08/2020</strong>
                </p>
                <p class="card-text mb-0">
                  Due Date: <strong>29/08/2020</strong>
                </p>
              </div>

              <div class="mt-md-0 mt-2">
                <h5 class="txt-black font-weight-bolder">Esther Howard</h5>
                <p>
                  <a href="mailto:esther.howard@mp.co.uk"
                  >esther.howard@mp.co.uk</a
                  >
                </p>
              </div>
            </div>
          </div>
          <hr class="invoice-spacing"/>
          <div class="card-body invoice-padding pt-0">
            <div class="row invoice-spacing py-2">
              <div class="col-xl-6 col-12 text-center text-md-left">
                <h4 class="mb-2 txt-black font-weight-bolder">Invoice To:</h4>
                <p class="txt-black font-weight-bolder">Primary Care Network</p>
                <p>
                  65 Newgate Street, London, IV47 0ZY, UK<br/>
                  718-986-6062<br/>
                  <a href="mailto:primarycare@mail.co.uk"
                  >primarycare@mail.co.uk</a
                  >
                </p>
              </div>
              <div
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end col-xl-6 col-12"
              >
                <div class="invoice-price">
                  <h2 class="txt-price txt-black font-weight-bolder mb-2">Balance Due: £2,240.00</h2>

                  <div class="table-responsive tbl-nl">

                    <table class="table b-table">
                      <tbody>
                      <tr>
                        <td class="pr-1">Bank name:</td>
                        <td>
                          <span class="font-weight-bold">Bank of England</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-1">IBAN:</td>
                        <td>ETD95476213874685</td>
                      </tr>
                      <tr>
                        <td class="pr-1">Sort code:</td>
                        <td>BR91905</td>
                      </tr>

                      </tbody>
                    </table>

                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive tbl-nl">
            <table
                aria-busy="false"
                aria-colcount="4"
                class="table b-table tbl-invoice"
                role="table"

            >
              <thead class="" role="rowgroup">

              <tr class="" role="row">
                <th aria-colindex="1" class="" role="columnheader" scope="col">
                  <div>Shift Date</div>
                </th>
                <th aria-colindex="2" class="" role="columnheader" scope="col">
                  <div>Hourly Rate</div>
                </th>
                <th aria-colindex="3" class="" role="columnheader" scope="col">
                  <div>HOURS</div>
                </th>
                <th aria-colindex="4" class="" role="columnheader" scope="col">
                  <div>PRICE</div>
                </th>
              </tr>
              </thead>
              <tbody role="rowgroup">

              <tr role="row">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    Feb 2, 2021
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£40.00</td>
                <td aria-colindex="2" class="" role="cell">4</td>
                <td aria-colindex="4" class="" role="cell">£160.00</td>
              </tr>
              <tr role="row">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    Feb 4, 2021
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£40.00</td>
                <td aria-colindex="2" class="" role="cell">8</td>
                <td aria-colindex="4" class="" role="cell">£160.00</td>
              </tr>
              <tr role="row">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    Feb 6, 2021
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£40.00</td>
                <td aria-colindex="2" class="" role="cell">4</td>
                <td aria-colindex="4" class="" role="cell">£160.00</td>
              </tr>
              <tr role="row">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    Feb 6, 2021
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£40.00</td>
                <td aria-colindex="2" class="" role="cell">6</td>
                <td aria-colindex="4" class="" role="cell">£160.00</td>
              </tr>
              <tr role="row">
                <td aria-colindex="1" class="" role="cell">
                  <p class="card-text font-weight-bold mb-25">
                    Feb 6, 2021
                  </p>
                </td>
                <td aria-colindex="2" class="" role="cell">£40.00</td>
                <td aria-colindex="2" class="" role="cell">6</td>
                <td aria-colindex="4" class="" role="cell">£160.00</td>
              </tr>
              <tr role="row">
                <td aria-colindex="1" class="" role="cell">&nbsp;</td>
                <td aria-colindex="2" class="" role="cell">&nbsp;</td>
                <td aria-colindex="2" class="py-2" role="cell">Total:</td>
                <td aria-colindex="4" class="font-weight-bolder" role="cell">£2,240.00</td>
              </tr>
              </tbody>
            </table>
          </div>


        </div>
      </div>

      <div class="col-xl-2 col-md-3">

        <div class="d-flex justify-content-end">

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="DownloadIcon" size="20"/>
          </b-button>

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="PrinterIcon" size="20"/>
          </b-button>

          <b-button
              class="shadow-sm font-weight-bold p-1 align-self-baseline float-left bg-white"
              style="margin-right: 4px;"
              variant="white">
            <feather-icon class="txt-green" icon="TrashIcon" size="20"/>
          </b-button>

        </div>


      </div>

    </div>


  </div>
</template>

<script>
import {BButton} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  }
}
</script>
